<!--
  仪表盘和组件库删除弹框
-->
<template>
  <el-dialog
    :title="$t('component_setup_tabs_label_delete')+type"
    :visible="componentsDelTo"
    width="528px"
    :before-close="ComponentsDelCancle"
    :close-on-click-modal="false"
  >
    <div class="center_center">
      <div>
        <p>{{$t('label.sure.delete')}}{{type}}“{{ name }}”？</p>
        <p>
          <!-- 此项操作不可撤回 -->
          {{$t("vue_label_norm_undone")}}
          </p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="ComponentsDelCancle">{{$t('button_partner_cancel')}}</el-button>
      <el-button type="primary" @click="ComponentsDelConfirm">{{$t('component_setup_tabs_label_delete')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    componentsDelTo: { // 是否显示弹框
      type: Boolean,
      default: true,
    },
    type: { // 删除类型：仪表板、文件夹
      type: String,
      default: "",
    },
    name: { // 删除对应的名称
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    //取消删除
    ComponentsDelCancle() {
      this.$emit("ComponentsDelCancle");
    },
    //确认删除
    ComponentsDelConfirm() {
      this.$emit("ComponentsDelConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  div {
    p:first-child {
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
    }
  }
}
</style>