  /* 
    新建仪表板、重命名、转移文件夹，共用一个弹框
   */
  <template>
  <!-- 添加仪表板弹框 -->
  <el-dialog
    :title="title"
    :visible="isShowCrateDashBoardAlert"
    width="528px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 仪表板名称：新建仪表板、重命名时均可以显示 -->
      <el-form-item
        :label="$t('label.newdashboardpage.name')"
        prop="name"
        v-show="ruleForm.id === '' || titleType === 'rename'"
      >
        <el-input
          v-model="ruleForm.name"
          :placeholder="$t('lable.product.please.enter')"
        ></el-input>
      </el-form-item>
      <!-- 仪表板所在文件夹：新建仪表板、转移文件夹时均可以显示 -->
      <el-form-item
        :label="$t('label.foldername')"
        prop="folder"
        v-show="ruleForm.id === '' || titleType === 'transfer'"
      >
        <el-select
          v-model="ruleForm.folder"
          filterable
          :placeholder="$t('label.select.please')"
        >
          <el-option
            v-for="item in allFolderList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="createDashBoardCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="createDashBoardSave('ruleForm')">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as request from "../../api.js";

export default {
  props: {
    isShowCrateDashBoardAlert: {
      type: Boolean,
      default: false,
    },
    dashboardfolderid: {
      // 文件夹id，新增时为''
      type: String,
      default: "",
    },
    dashboardInfos: {
      // 仪表板信息
      type: Object,
      default: () => {},
    },
    titleType: {
      // 对仪表板的重命名、转移文件夹
      type: String,
      default: "",
    },
  },
  data() {
    return {
      allFolderList: [], //文件夹列表
      myPersonDash: "", //我的个人仪表板的id
      ruleForm: {
        id: "", // 仪表板id
        name: "", // 仪表板名称
        folder: "", // 文件夹id
        width: 12, // 仪表板宽度，确定为12
        height: 0, // 仪表板高度
      },
      rules: {
        name: {
          // 仪表板名称设置为必填
          required: true,
          message: this.$i18n.t("lable.product.please.enter"),
          trigger: "blur",
        },
        folder: {
          // 文件夹名称设置为必填
          required: true,
          // "请输入文件夹名称"
          message: this.$i18n.t("vue_label_dashboard_pleaseenterafoldername"),
          trigger: "blur",
        },
      },
    };
  },
  computed: {
    // 计算title
    title() {
      let title=''
      if (this.ruleForm.id !== "") {
        if (this.titleType === "rename") {
          // 重命名
          title= this.$i18n.t("label.rename");
        } else if (this.titleType === "transfer") {
          // 转移文件夹
          title= this.$i18n.t("vue_report_dashboard_transfer");
        }
      } else {
        // 新建
        title= this.$i18n.t("label.dashboard.new");
      }
      return title
    },
  },
  watch: {
    dashboardInfos: function () {
      if (this.dashboardInfos.id) {
        // 编辑回显
        this.ruleForm = {
          id: this.dashboardInfos.id,
          name: this.dashboardInfos.name,
          folder: this.dashboardInfos.dashboardfolderid,
          width: this.dashboardInfos.width,
          height: this.dashboardInfos.height,
        };
      } else {
        // 新建
        this.ruleForm = {
          id: "",
          name: "",
          folder: this.myPersonDash[0].id,
          width: 12,
          height: 0,
        };
      }
    },
  },
  created() {
    this.getDashboardFolderList();
  },
  methods: {
    /**
     * 设置默认,父组件调用
     */
    chooseSelectFolder() {
      // 1、如果在点击某个文件夹后点击新建仪表板则默认保存为当前文件夹。
      // 2、列表是【最近查看】【我创建的仪表板】【全部仪表板】点击新建仪表板，默认保存到我的个人仪表板。
      if (
        this.allFolderList &&
        this.dashboardfolderid &&
        this.dashboardfolderid != "recentDashboard" &&
        this.dashboardfolderid != "thedashboardicreated" &&
        this.dashboardfolderid != ""
      ) {
        this.ruleForm.folder = this.dashboardfolderid;
      }else if(this.myPersonDash&&this.myPersonDash[0].id){
        this.ruleForm.folder = this.myPersonDash[0].id
      }
    },
    handleClose() {
      this.$emit("createDashBoardCancel");
    },
    //获取所有文件夹列表
    async getDashboardFolderList() {
      var params = {
        searchKeyWord: "",
      };
      let res = await request.getDashboardFolderList(params);
      this.allFolderList = res.data.allFolderList;
      this.myPersonDash = this.allFolderList.filter(
        (item) => item.name === this.$i18n.t("label.myselfdashboard")
      );
      if(this.myPersonDash){
        this.ruleForm.folder = this.myPersonDash[0].id
      }
    },
    // 点击确定
    createDashBoardSave(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$forceUpdate();
          /*--
            id	是	string	仪表板ID
            name	是	string	名称
            description	否	string	描述
            dashboardfolderid	否	string	文件夹ID
            islightning	是	string	是否是lightning仪表板。true:是；false：不是。
            width	否	string	仪表板的宽带
            height	否	string	仪表板的高度 
          */
          let params = {
            id: this.ruleForm.id,
            name: this.ruleForm.name,
            description: "",
            dashboardfolderid: this.ruleForm.folder,
            islightning: true,
            width: this.ruleForm.width,
            height: this.ruleForm.height,
          };
          if (this.ruleForm.id !== "") {
            // 有id，在重命名
            await request.updateDashboard(params);
            this.$message.success(
              this.$i18n.t("vue_label_dashboard_dashboard")
            ); //重命名仪表板成功！
          } else {
            // 无id，在新增
            let { data } = await request.addDashboard(params);
            this.$message.success(
              this.$i18n.t("vue_label_dashboard_newdashboardsuccessfully")
            ); //新建仪表板成功
            this.$router.push({
              path: `/dashboardObject/dashboardIndex/${data.id}`,
              query: {},
            });
          }
          this.$emit("createDashBoardSave");
          this.$emit("createDashBoardCancel");
          this.ruleForm = {
            id: "",
            name: "",
            folder: "",
            width: 12,
            height: 0,
          };
        } else {
          this.ruleForm = {
            id: "",
            name: "",
            folder: "",
            width: 12,
            height: 0,
          };
          return false;
        }
      });
    },
    createDashBoardCancel() {
      this.$emit("createDashBoardCancel");
      this.ruleForm = {
        id: "",
        name: "",
        folder: this.$i18n.t("label.myselfdashboard"),
        width: 12,
        height: 0,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 30px;
  width: 360px;
}
::v-deep .el-form {
  max-height: 157px;
}
.el-form-item__label {
  word-break: normal;
}
</style>
